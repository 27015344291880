import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useRegBookDown } from './useRegBookDown';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
export const RegistrationsBookingsDownloads = () => {

    const { options, data } = useRegBookDown();

    return <div className='chart'><Line className='chart' options={options} data={data} /></div>;
}
