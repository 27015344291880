import { useEffect, useState } from "react";

export const useRegBookDown = () => {

    const [chartData, setChartData] = useState({
        dates: [],
        userCounts: [],
        eventCounts: [],
        androidDownloads: []
    });


    const fetchData = async () => {
        try {
            const response = await fetch('https://api.sfg.foxberry.live/v1/user/getUserRegistrations');
            const data = await response.json();

            const formattedData = {
                dates: data.registrationData.map(item => item.date.slice(0, 10)),
                userCounts: data.registrationData.map(item => item.userCount),
                eventCounts: data.registrationData.map(item => item.eventCount),
                androidDownloads: data.appData.map(item => item.android),
            };

            setChartData(formattedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Registration/Bookings/Downloads',
            },
        },
    };

    const labels = labelsGenerator(chartData.dates);


    const data = {
        labels,
        datasets: [
            {
                label: 'Registrations',
                data: chartData.userCounts,
                borderColor: '#8338ec',
                backgroundColor: '#8338ec',
            },
            {
                label: 'Bookings',
                data: chartData.eventCounts,
                borderColor: '#2a9d8f',
                backgroundColor: '#2a9d8f',
            },
            {
                label: 'Downloads (Android)',
                data: chartData.androidDownloads,
                borderColor: '#1d3557',
                backgroundColor: '#1d3557',
            },
        ],
    };


    useEffect(() => {
        fetchData();
    }, [])


    function cumulativeSum(arr, debug=false) {
        const result = [arr[0]]; // Initialize the first element

        for (let i = 1; i < arr.length; i++) {
            if(debug) console.log('[arr item]', +result[i - 1] + +arr[i])
            result.push(+result[i - 1] + +arr[i]);
        }

        return result;
    }



    return {
        liveData: chartData,
        cumulativeSum,
        options,
        labels,
        data
    }
}


// Helpers

const labelsGenerator = (dates) => {
    return dates.map(date => {
        const day = new Date(date).getDate();
        const month = new Date(date).toLocaleDateString()
        return `${month}`
    })
}

const getMonth = (num) => {

}


function convertDate(inputDate) {
    const [month, day, year] = inputDate.split('/');

    const date = new Date(year, month - 1, day); // -1 for zero-based months in Date object

    const options = { day: 'numeric', month: 'long' };
    return date.toDateString('en-US', options);
}
