import React from 'react';
import "./App.css";
import { RegistrationsBookingsDownloads } from './charts/RegistrationsBookingsDownloads';
import { RegistrationsBookingsDownloadsCumulative } from './charts/RegistrationsBookingsDownloadsCumulative';

export default function App() {
  return <div style={{padding: '10px',}}>
    <h3>Sri Family Guide</h3>
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
      <div className='card' style={{ flex: 1 }}>
        <RegistrationsBookingsDownloads />
      </div>
      <div className='card' style={{ flex: 1 }}>
        <RegistrationsBookingsDownloadsCumulative />
      </div>
    </div>
  </div>
}
