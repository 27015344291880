import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useRegBookDown } from './useRegBookDown';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
export const RegistrationsBookingsDownloadsCumulative = () => {

    const { liveData, labels, cumulativeSum } = useRegBookDown();

    const cumulativeSums = useMemo(() => {
        return {
            registrations: cumulativeSum(liveData.userCounts),
            bookings: cumulativeSum(liveData.eventCounts),
            android: cumulativeSum(liveData.androidDownloads),
        }
    }, [liveData])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Cumulative',
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Registrations',
                data: cumulativeSums.registrations,
                borderColor: '#8338ec',
                backgroundColor: '#8338ec',
            },
            {
                label: 'Bookings',
                data: cumulativeSums.bookings,
                borderColor: '#2a9d8f',
                backgroundColor: '#2a9d8f',
            },
            {
                label: 'Downloads (Android)',
                data: cumulativeSums.android,
                borderColor: '#1d3557',
                backgroundColor: '#1d3557',
            },
        ],
    };

    return <div className='chart'><Line className='chart' options={options} data={data} /></div>;
}

